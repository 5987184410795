@import "../../assets/scss/index.scss";

.notFound
{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    max-width: 1500px;

    
    @include mq("tablet-wide","min")
    {
        min-height: 80vh;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .image404
    {
        min-width: 100px;
        max-width: 273px;
        margin: 60px;
        
        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include mq("tablet-wide","min")
        {
            max-width: 273px;
        }
    }

    .content404
    {
        font-family: Mulish;
        text-align: center;
        color: $primary-text-color;
        padding: 0 10px 124px;
        max-width: 350px;

        .oops
        {
            
            font-size: 25px;
            font-weight: 800;
        }

        .apology
        {            
            font-size: 18px;
            font-weight: 600;
            line-height: 1.44;
            margin: 30px auto 57px;
        }

        .back
        {
            color:#fff;
            background-color:$primary-color ;
            padding: 14px 50px;
            font-size:18px;
            border-radius: 4px;

            @include mq("tablet-wide","min")
            {
                padding: 14px 90px;
            }
        }
    }
}
