@import "../../../assets/scss/index.scss";

footer {
  min-height: 20vh;
  background: $primary-color;
  color:#fff;
  padding-top:100px !important;
  .inner {
    display: flex;
    .nav {
      display: flex;
      width: 100%;
      a{
        color:#fff;
      }
      ul {
        width: 50%;
        padding: 0 30px;
        color: #FFF;
        text-align: center;
        li {
          font-size: 16px;
          margin: 15px;

          &.ft_title {
            font-weight: map-get($font-weights, "extrabold");
            font-size: 18px;
          }
          
          a
          {
            color: #fff;
          }
        }
      }
    }
    
    @include mq("tablet") {
      flex-wrap: wrap;
      text-align: center;
      .nav{
        width:100%;
      }
      .nav {
        flex-wrap: wrap;
        ul {
          width: 153px;
          padding: 0px;
        }
      }
    }
  }
  .socials{
    margin:56px auto ;
    text-align: center;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    span{
      display: inline-block;
      font-size: 20px;
       margin:10px 31px;
       height:50px;
       width:50px;
      color:$primary-color;
      @include flex-center;
    

      img
      {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .copyright{
    border-top:1px solid rgba(255,255,255,0.4);
    height:70px;
    margin-top:20px;
    @include flex-center;
    font-size: 14px;
    text-align: center;
  }
  @include mq("tablet"){
    padding:20p 10px ;
    .wrap{
      display: flex;
     .inner{
        width: 60%;
      }
      .footer_bottom{
        width: 40%;
        align-self: center;
      }
    }

   }
   @include mq("tablet-small"){
     padding:50px 0px !important;
     .wrap{
       flex-wrap: wrap;
       .inner, .footer_bottom{
         width:100%;
       }
     }
     .inner .nav ul{
       width: 50%;
     }
   }
   @media (max-width:318px){
    .inner .nav ul{
      width: 100%;
    }
   }
}
