@import "./assets/scss/index.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800;900&display=swap');


* {
  margin: 0px;
  padding: 0px;
  box-sizing:border-box;
}


html,
body {
  font-family: 'Mulish', Romans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1px;
  color: $primary-text-color;
  width: 100%;
}
ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  
.main {
  flex: 1;
  min-height: 50vh;
  margin-top: 0;
  // @media (max-width: 768px) {
  //   margin-top: 10vh !important;
  // }
}


.btn-primary{
  @extend %btn;
   background-color: $primary-color;
  color:#fff;
}
.btn-launch{
  @extend %btn;
  border-radius: 0px;
  background-color: $primary-color;
  color:#fff;
  font-size: 12px;
  @include mq("tablet"){
    padding: 10px;
    // font-size: 14px;
  }
}
.btn-ghost{
  @extend %btn;
  color:$primary-text-color;
  background:transparent;
  border:1px solid $primary-color;
  font-weight: map-get($font-weights, "light" );
}
.btn-round{
  border-radius: 38px;
  padding: 12px 19px 12px 18px;
}
a {
  color: $secondary-text-color;
  text-decoration: none;
}
li {
  list-style-type: none;
}
p{
    font-weight: map-get($font-weights, "regular" );
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: map-get($font-weights, "regular" );
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}
img {
  width: 100%;
  height: 100%;
}
input:disabled {
  background: #f1f1f1;
}

/**
 * Fluid background, fixed content
 */
 header, section, footer {
  padding: 0.5em;
  padding: 0.5em calc(30% - 350px);
  @include mq("desktop",max){
    padding: 0.5em 30px;
  }
  
}
.section{
    width:100%;
}
@media (max-width: 270px) {
  body {
    font-size: 6px;
  }
}

body::-webkit-scrollbar {
  width: 15px;
}
 
body::-webkit-scrollbar-track {
  background-color: $inactive;
}
 
body::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border: 1px solid #fff;
  border-radius: 15px;
}
