@import "../../assets/scss/index.scss";

/* The actual timeline (the vertical ruler) */
$anchor-left-pos:-56px;
$anchor-pole-width:2px;
$anchor-left-pole:calc(#{$anchor-left-pos} + (#{$anchor-pole-width+3} * 2));
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    //hide the last line on the timeline
    & > div:last-child::before{
        height: 0px;
    }
  }
  .tp{
      font-family: Helvetica, sans-serif;
  }
  /* The actual timeline (the vertical ruler) */
  
  
  /* Container around content */
  .container {
    padding-top: 15px;
    padding-left: 30px;
    position: relative;
    background-color: inherit;
    margin-top: 10px;
    min-height: 70px;
    width: 230px;
    font-size: 20px;
    //generate numbered classes from 1-10
    @for $i from 1 through 10 {
      &.pseu-#{$i}::after { 
        @if ($i < 10){
          content: '0' + $i;
        }@else{
          content: $i;
        }
       }
      }
  }
  
  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    // background-color: white;
    // border: 4px solid blue;
    top: 18px;
    // border-radius: 50%;
    z-index: 1;

  }
  .container::before{
    position: absolute;
    content:"";
    width:$anchor-pole-width;
    background:#fff;
    height: 50px;
    top: 48px;
  }
  /* Place the container to the left */
  .left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    left: 10.5%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
   left:$anchor-left-pole;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: $anchor-left-pos;
  }
  
  /* The actual content */
  .content {
    padding: 5px 20px;
    position: relative;
    border-radius: 6px;
    color:#fff;
    font-weight: 600;

      p
      {  
        font-size: 18px;     

        @include mq("tablet","min")
        {
          font-size: 20px;
        }
        
        @include mq("desktop-wide","min")
        {
          font-size: 22px;
        }
      };
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {

  /* Full-width containers */
    .container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
  /* Make sure that all arrows are pointing leftwards */
   
  
  /* Make sure all circles are at the same spot */
    .left::after, .right::after {
      left: 15px;
    }
    .right::before{
      left:22px;
    }
  
  /* Make all right containers behave like the left ones */
    .right {
      left: 0%;
    }
  }
