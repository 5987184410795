@mixin flex-center($direction: row) {
  display: flex;
  flex-flow: $direction;
  justify-content: center;
  align-items: center;
}
@mixin flex-btw($direction: row, $wrap: nowrap) {
  display: flex;
  flex-flow: $direction;
  justify-content: space-between;
  align-items: center;
  flex-wrap: $wrap;
}
@mixin flex-even($direction: row, $wrap: nowrap) {
  display: flex;
  flex-flow: $direction;
  justify-content: space-around;
  align-items: center;
  flex-wrap: $wrap;
}

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        // @if $type == max {
        //     $width: $width - 1px;
        // }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin fade-transition($element: all) {
  -webkit-transition: $element 0.3s ease-in-out;
  -moz-transition: $element 0.3s ease-in-out;
  -ms-transition: $element 0.3s ease-in-out;
  -o-transition: $element 0.3s ease-in-out;
  transition: $element 0.3s ease-in-out;
}



@mixin menu-style {
  position: absolute;
  width: auto;
  box-shadow: $menu-shadow;
  padding: 10px 0;
  z-index: 50;
  background: #fff;
  margin-top: 25px;
  border-radius: 6px;
  @include fade-transition;
  transform-origin: top;
  z-index: 6;
  float: left;
  li {
    padding: 10px 20px;
    &:hover {
      background: $borders_and_disabled;
    }
  }
}

@mixin styledScrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(168, 159, 159);
    border-radius: 1rem;
  }
}

@mixin textInputs {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"] {
    @content;
  }
}
@mixin textInputsFocus {
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="number"] {
    @content;
  }
}
input[type="checkbox"].input-error {
  outline: 2px solid $error !important;
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}
@mixin shadow-effect1{
    position: relative;
    &:before, &:after
    {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width:300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &:after
    {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
}

@mixin shadow-effect2{
    position: relative;
    &:before, &:after
    {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 25px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width:300px;
      background: #777;
      -webkit-box-shadow: 0 35px 20px #777;
      -moz-box-shadow: 0 35px 20px #777;
      box-shadow: 0 35px 20px #777;
      -webkit-transform: rotate(-8deg);
      -moz-transform: rotate(-8deg);
      -o-transform: rotate(-8deg);
      -ms-transform: rotate(-8deg);
      transform: rotate(-8deg);
    }
    &:after
    {
      -webkit-transform: rotate(8deg);
      -moz-transform: rotate(8deg);
      -o-transform: rotate(8deg);
      -ms-transform: rotate(8deg);
      transform: rotate(8deg);
      right: 10px;
      left: auto;
    }
}

@mixin shadow-effect3
{
  position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

&:before, &:after
{
content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
&:after
{
right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

}
