@import "../../assets/scss/index.scss";

.hero_with_app {
  min-height: 20vh;
  background: url("/images/hero_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 70px;
  display: flex;
  padding-top: 100px;
  padding-bottom: 104px;
  position: relative;
  .mobile-rate {
    position: absolute;
    right: 50px;
    top: 15px;
    display: none;
    @include mq("tablet-small") {
      display: inline-block;
    }
  }
 
  @include mq("tablet") 
  {
    flex-flow: column;
    & > div {
      width: 100%;
    }
  }

  @include mq("fluid-out")
  {
    padding-top: 75px;
  }

  #{&}__text {
    text-align: left;
    width:55%;
    padding: 30px 0;
    @include mq("tablet"){
          margin: auto;
          width: 100%;
      
      }
    .text_wrap {
      @include mq("tablet") {
        text-align: center;
        max-width: 488px;
        margin: auto;
      }
      h1 {
        font-size: 28px;
        font-weight: map-get($font-weights, "black");
        span {
          color: $primary-color;
        }
        
         @include mq("phone","min") 
         {
        font-size: 30px;
      }

       @include mq("tablet","min") 
         {
        font-size: 36px;
      }

       @include mq("tablet-wide","min") 
         {
        font-size: 42px;
      }
      
       @include mq("desktop-wide","min") 
         {
        font-size: 48px;
      }
      }
      p {
        font-size: 12px;
        margin-top: 25px;
        line-height: 2.8;

        @include mq("tablet","min") 
            {
            font-size: 16px;
          }

          @include mq("tablet-wide","min") 
            {
            font-size: 20px;
          }
          
          @include mq("desktop-wide","min") 
            {
            font-size: 22px;
          }

        @include mq("fluid-out","min")
        {
          max-width: 70%;
          line-height: 2;
        }
      }
    }
  }
  #{&}__app {
    min-height: 400px;
    position: relative;
    width:45%;
    @include mq("tablet"){
      margin: auto;
      width: 100%;
      padding-left:0px;
  }
   
  @include mq("phone")
      {
         ._NiZUc ._k_nWf
         {
           --input-width: 60% !important;
         }
      }
   
    & > div
    {
      background: none !important;
      box-shadow: none !important;
      padding: 0px !important;
      
      @include mq("tablet-wide","min")
      {
        max-width: unset
      }
      
      // @include mq("tablet-wide","min")
      // {
      //     transform:translateX(3%)
      // }

      // @media screen and (min-width:1200px) and (max-width:2000px)
      // {
      //     transform:translateX(18%)
      // }
    }
    
  }
 
}
.crypto_grid_rate {
  min-height: 30vh;
  margin: 59px 0;

  @include mq("phone","min")
  {
    margin: 57px 0;
  }

  @include mq("tablet","min")
  {
    margin: 85px 0;    
  }

  @include mq("desktop-wide","min")
  {
    margin: 62px 0;    
  }


  #{&}__grid {
   
    @include mq("tablet","min")
    {
       //Flex fallback for browsers that don't support grid
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @supports (display: grid) {
        display: grid;
        padding: 10px 0;
        grid-gap: 80px;
        @include mq("phablet") {
          padding: 10px 24px;
        }
        @include mq("desktop", min) {
          grid-template-columns: repeat(auto-fit, minmax(286px, 1fr));
        }
        grid-template-columns: repeat(auto-fit, minmax(286px, 1fr));
        @include mq("phone"){
          grid-template-columns:100%; 
        }
        justify-content: space-evenly;
      }
    }
    
    &__item {
      min-height: 120px;    
      border-radius: 4px;
      padding: 20px;
      max-height: 220px;

      @include mq("tablet","min")
      {
        box-shadow: 0px 30px 80px #8b95db4d;
      }
     
      .info {
        display:flex;
        justify-content: space-between;
        align-items: center;
        .info_title {
          @include flex-center;
          justify-content: flex-start;
          img {
            width: 27px;
            margin-right: 10px;
            &.eth {
              height: 27px;
            }
          }
          h2 {
            font-weight: map-get($font-weights, "extrabold");
            color: $primary-color;
            font-size: 22px;

            @media screen and (max-width:300px)
            {
                font-size: 15px !important;
           
            }
          }
        }
        .current_rate {
          margin-top: 5px;
          word-break: break-all;
          p {
            color: #000;
            font-size: 18px;            
          margin: 10px 0;
            font-weight: map-get($font-weights, "semibold");
          }
          small {
            color: #8f8f8f;
            font-size: 12px;

            .loss
            {
              color: $error;
            }

            .profit
            {
              color: $success;
            }
          }

          @media screen and (max-width:300px)
          {
            p,small
            {
              font-size: 10px !important;
            }
            
          }
        }
      }

      .graph 
      {
        height: 50%;
        transform: scale(1,0.4);
      }
    }
    @include mq("phablet") {
      &__item {
        max-width: 100%;
        .info .current_rate p {
          font-size: 18px;
        }
      }
    }
    @include mq("phone"){
      &__item  .info .current_rate p{
          font-size: 15px;
      }
    }

  }
  @include mq("phone"){
    padding: 0px;
  }
}


.title {
  font-weight: map-get($font-weights, "black");
  text-align: center;
  font-size: 26px;
  max-width: 400px;
  margin: 30px auto;
}

.fadeInUp {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 40px);
  animation-duration: 0.6s;
  animation-name: fadeInUp;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.platform_offer {
  min-height: 30vh;
  .grid_item {
    @include flex-center(column);
    background: #ddeaff61 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 35px;
    i {
      font-size: 45px;
    }
    img{
      width:45px;
      height:45px;
    }
    h3 {
      margin-top: 10px;
      font-size: 22px;
      font-weight: map-get($font-weights, "semibold");
      text-align: center;
    }
  }
}

.steps {
  background: linear-gradient(to right, $primary-color 50%, #3a4ccc 50%);
  display: flex;
  color: #fff;
  min-height: 30vh;
  padding: 0px;
  & > div {
    width: 100%;
    padding-bottom: 73px;
    @include flex-center(column);
    justify-content: flex-start;
  }
  @include mq("tablet") {
    
  margin-top: 40px;
    flex-wrap: wrap;
    background: linear-gradient(to bottom, $primary-color 50%, #3a4ccc 50%);
    h2 {
      text-align: center;
    }
  }
  h2 {
    font-weight: map-get($font-weights, "bold");
    font-size: 22px;
    margin: 53px auto 56px;
    
    @include mq("phone","min")
    {
      font-size: 24px;
      margin: 51px auto 56px;
    }
    
    @include mq("tablet","min")
    {
      font-size: 24px;
      margin: 66px auto 55px;
    }
    
    @include mq("tablet-wide","min")
    {
      margin: 41px auto 55px;
    }
    
    @include mq("desktop-wide","min")
    {
      font-size: 35px;
      margin: 58px auto 76px;
    }


  }
  .cta {
    @extend %btn;
    width: 300px;
    margin-top: 50px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    @include mq("phone"){
      width: 80%;
    }

     
    font-size: 18px;     

    @include mq("tablet","min")
    {
      font-size: 20px;
    }
    
    @include mq("desktop-wide","min")
    {
      font-size: 22px;
    }
  }
}

.payments {
  min-height: 20vh;

  .title
  {
    margin-top: 70px;  
    font-size: 22px;   

    @include mq("phone","min")
    {
      margin-top: 90px;  
    }  

    @include mq("tablet","min")
    {
      margin-top: 120px;  
      font-size: 26px;   
    }  

    @include mq("desktop","min")
    {
      margin-top: 135px;
      font-size: 30px;   
    }
    
    @include mq("desktop-wide","min")
    {
      margin-top: 150px;
      font-size: 35px;   
    }
  }
  .methods {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
    .method_box {
     flex-basis: 20%;
      max-height: 74px;
      text-align: center;
      margin: 10px;
     margin-bottom: 40px;

     @include mq("desktop")
     {
       max-width: 100px;
     }

      p {
        font-size: 20px;
        font-weight: map-get($font-weights, "semibold");
        text-align: center;
        margin-top: 10px;
      }
    }
    @include mq("phablet"){
      flex-wrap: wrap;
      justify-content: center;
      .method_box {
        max-width:25%; 
        padding: 10px;
        p{
          font-size: 16px;
        }
      }
    }

     @include mq("fluid-out"){
      margin-top: 60px;
     
    }
   

  }

  
     @include mq("fluid-out"){
      padding: 0 10px
     
    }
  
}

.testimonials {
  min-height: 20vh;

  .title
  {
    max-width: 500px;
    margin-bottom: 40px;
    margin-top: 70px;  
    font-size: 22px;   

    @include mq("phone","min")
    {
      margin-top: 90px;  
    }  

    @include mq("tablet","min")
    {
      margin-top: 120px;  
      font-size: 26px;   
    }  

    @include mq("desktop","min")
    {
      margin-top: 135px;
      font-size: 30px;   
    }
    
    @include mq("desktop-wide","min")
    {
      margin-top: 150px;
      font-size: 35px;   
    }
  }
  @media (max-width: 927px) and (min-width: 768px) {
    justify-content: center;
    
  }
  .testimonial {
    box-shadow: 0px 30px 80px #8b95db4d;
    border-radius: 4px;
    padding: 0px !important;
    @include mq("fluid-out"){
    box-shadow: 0px 2px 8px #8b95db4d;
    height: 100%;

    

    @media screen and (min-width:560px) and (max-width:767px)
    {
      min-width: 60vw;
    }
    
   
  }
  
    

    @include mq("phablet")
    {
     width: 54vw;
    }

    @include mq("phone")
    {
     width: 64vw;
    }


    .customer_quote {
      height: 65%;
      text-align: center;
      padding: 20px;
      color: #022b69;
      font-size: 12px;
      line-height: 2;


      @include mq("phone","min")
      {
       font-size: 16px;
      }

      @include mq("tablet","min")
      {
       font-size: 12px;
      }

      @include mq("tablet-wide","min")
      {
        
      line-height: 1.5;
       font-size: 18px;
      }      


      .quote_icon {
        width: 6.04px;
        height: 9.47px;
        text-align: left;

        @include mq("phone","min")
        {
          width: 7.35px;
          height: 11.53px;
        }

        @include mq("tablet","min")
        {
          width: 6.04px;
          height: 9.47px;
        }

        @include mq("tablet-wide","min")
        {
          width: 7.35px;
          height: 11.53px;
        }

        @include mq("desktop-wide","min")
        {
          width: 26.57px;
          height: 26.26px;
        }
      }
    }
    .customer_info {
      border-left: 4px solid $primary-color;
      height: 25%;
      padding: 0px 10px 0px 20px;      
      margin-bottom: 10px;
      margin-top: 10px;
      @include flex-center;
      justify-content: flex-start;
      h4 {
        font-weight: map-get($font-weights, "bold");
        color: #022b69;
        margin-left: 10px;

        @include mq("phone","min")
        {
          font-size: 16px;
        }

        @include mq("tablet","min")
        {
          font-size: 12px;
        }

        @include mq("tablet-wide","min")
        {
          
          line-height: 1.5;
          font-size: 18px;
        }      


      }
      img {
        width: 35px;
        height: 35px;

        @include mq("phone","min")
        {
          width: 43px;
          height: 43px;
        }

        @include mq("tablet","min")
        {
          width: 35px;
          height: 35px;
        }

        @include mq("tablet-wide","min")
        {
          width: 43px;
          height: 43px;
        }

        @include mq("desktop-wide","min")
        {
          width: 63px;
          height: 63px;
        }
      }
    }
  }
  @include mq("phone"){
    padding:0px;
    .title{
      padding:10px;
    }
  }

  

}
.newsletter {
  min-height: 30vh;
  padding-top: 0;
  h2 {
    font-weight: map-get($font-weights, "semibold");
    max-width: 650px;
    font-size: 12px;   

    @include mq("phone","min")
    {
      font-size: 14px;   
    }  


    @include mq("tablet","min")
    {
      font-size: 20px;   
    }  

    @include mq("tablet-wide","min")
    {
      font-size: 22px;   
    }
    
    @include mq("desktop-wide","min")
    {
      font-size: 25px;   
    }
    
  }
  .input_form {
    width: 100%;
    @include flex-center(column);
    input {
      width: 50%;
      height: 50px;
      border-radius: 4px;
      border: 1px solid $primary-color;
      margin: 20px;
      padding-left: 20px;
      font-size: 18px;
      max-width: 620px;
      &::placeholder {
        color: #afafaf;
      }
    }
    button {
      border-radius: 4px;
      width: 270px;
      max-width: 100%;
      font-size: 12px;   
        margin-top: 45px; 

      @include mq("phone","min")
      {
        font-size: 14px;   
        margin-top: 50px;
      }  


      @include mq("tablet","min")
      {
        font-size: 20px;   
        margin-top: 60px;
      }  

      @include mq("tablet-wide","min")
      {
        font-size: 22px;  
        margin-top: 50px; 
      }
      
      @include mq("desktop-wide","min")
      {
        font-size: 25px;  
        margin-top: 50px; 
      }
    }

    .error
    {
      color:$error;
      font-size:16px;
    }

    .loading
    {
      margin-top: 40px; 
      opacity: 1;      
      font-size:20px;
      animation: pulse 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    }

    @keyframes pulse 
    {
      0%,100%
      {
        opacity: 1;
      }

      50%
      {
        opacity: 0.4;
      }
      
    }

    @include mq("tablet") {
      input {
        width: 80%;
      }
    }

     margin-bottom: 139px; 
      @include mq("phone","min")
      {
        margin-bottom: 148px;   
      }  


      @include mq("tablet","min")
      {
        margin-bottom: 187px;   
      }  

      @include mq("tablet-wide","min")
      {
        margin-bottom: 202px;   
      }
      
      @include mq("desktop-wide","min")
      {
        margin-bottom: 212px;   
      }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    visibility: hidden;
    //transform: translate(0, 40px);
  }
  to {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0px);
  }
}

//FLEX GRID HELPER CLASS
.grid_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .grid_item {
    color: $primary-color;
    min-height: 176px;
    min-width: 260px;
    padding: 10px;
    margin-bottom: 10%; /* (100-32*3)/2 */
    flex-basis: 30%;
    @include mq("tablet") {
    padding: 10px;
    min-width: 200px;
    min-height: 166px;
    flex-basis: 205px;
    
  }
  }
  
  @include mq("tablet-small") {
    .grid_item {
      max-width: 100%;
      width: 100%;
    }
  }

   @include mq("desktop","min") {
    padding-top: 80px ;
     justify-content: space-between;
  }
}


// END OF FLEX GRID HELPER CLASS

.first
{
  .grid_item
  {
    max-width: 272px;
  }

  @include mq("desktop-wide","min")
  {
    padding-left: 50px;
    padding-right: 50px;
  }
}
