.wrapper
{
    overflow-x: hidden; 
    margin: 0 4% ;
    padding-top: 10px;
    position: relative;

    .slider
    {
        padding:0;
        display: flex;
        flex-wrap: nowrap;  
        justify-content: space-between;
        transform: translateX(0);
        transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

        .slide
        {
            width: 85%;
            box-shadow: 0px 0px 10px #8b95db4d;
            margin: 0 12px;
        } 
        
       
    }

    .controls
    {
        display: flex;
        margin: 24px auto 5px;
        justify-content: center;
        align-items: center;

        button
        {
            border: none;
            color: transparent;
            background-color: #a9caff;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            margin: 0 10px;
        }

        .active
        {
            background-color: #022b69;
        }
    }
}
