@import "../../../assets/scss/index.scss";

.header {
    position:fixed;
    left: 0;
    right: 0;
    top:0;
    width: 100%;
    height: 70px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:23px;
    padding-bottom:23px ;
    box-shadow:$shadow;
    background:#fff;
    z-index: 999;
    .menu-open{
      font-size: 15px;
      color:$primary-color;
      padding:3px 9px;
      border-radius: 4px;
      transform: translateX(-20px);
      display: none;
      @include mq("tablet"){
        display: inline-block;
      }
    }
    .logo{
      width: 120px;
      padding-top: 6px;
      @media (max-width:318px){
       display: none;
      }
      
    }
    nav{
      display: flex;
      @include flex-center;
      flex-shrink: 0;
      height:100%;
      ul{
        display: flex;
        align-items: center;
        @include mq("fluid-out","min")
        {
            margin-left: 35px;
        }
      
        li{
          margin: 10px 23px;

          select
          {
            outline: none;
            text-align: center;
            padding:5px;
          }
        }

     
      }



      @include mq("tablet","min")
      {
        .right-nav
        {
            transform:translateX(22px)
        }
      }
      &.menu{
        li {
          font-size: 18px;
          color:$primary-text-color;
          font-weight: map-get($font-weights, "semibold");
        }
        span.close{
          display: none;
         
        }

        span i
        {
            transform: scale(1.8);
        
        }
        @include mq("tablet"){
          ul{
            position: fixed;
            background:$primary-text-color;
            box-shadow: $shadow;
            height: 100%;
            top:0;
            bottom:0;
            left:-280px;
            width:270px;
            padding:100px;
            display: block;
            text-align: center;
            transition: all .5s;
            &.open{
              left:0;
              z-index: 10;
            }
            @include flex-center(column);
            a  li{
              color:#fff;
            }
            span.close{
              display: block;
              position: absolute;
              top:15px;
              right:30px;
              color:#fff;
              font-size: 25px;
            }
          }
        }
      }
    }
   
    @include mq("tablet-small"){
     .rate{
        display: none;
      }
     nav ul li{
        margin: 10px;
      }
    }
  }

      

.btn-ghost.hidden-large
{
  display: none;
  z-index: 10;
  outline: none;
}
@include mq("phablet")
{
  .btn-ghost.hidden-large
  {
    display: initial;
    position: absolute;
    right: 5%;
    top:17%;          
  }
}
