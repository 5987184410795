@import "../../assets/scss/config/index";

$acc-width:85%;
$offset: calc( 100% - (#{$acc-width}));
$text-offset:-155px;

/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;

    @include mq("tablet-wide","min")
    {
      width: $acc-width;
      transform: translateX( $offset );
    }
  }
  
  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border:none;
    border-top:2px solid #eee ;
    outline: none;
    transition: background-color 0.6s ease;
  }

  .accordion__section:last-of-type .accordion
  {
    border-bottom:2px solid #eee;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #fff;
  }
  
  /* Style the accordion content title */
  .accordion__title {
    font-size: 18px;
    font-weight: map-get($font-weights, "bold" );
    text-align: left;
    color:$primary-color;  

     @include mq("tablet-wide","min")
    {
      font-size: 22px;
      transform: translateX($text-offset);
      max-width: 250px;
      word-wrap: break-word;
    }

     @include mq("tablet")
    {
      padding-right: 20px
    }
  }

  .faq .accordion__title
  {
      @include mq("tablet-wide","min")
    {
      max-width: unset;
    }
  }
  
  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    font-size: 30px;
    color: $primary-color;
    transition: transform 0.4s cubic-bezier(0.2, 0.84, 0.44, 1);
  }
  
  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(45deg);
    color: #DC0000;
  }
  
  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow:hidden;    
    transition: max-height 0.4s cubic-bezier(0.2, 0.84, 0.44, 1);
  }
  
  
  /* Style the accordion content text */
  .accordion__text {
    padding: 30px 10px 50px;
    line-height: 2;
    font-size: 14px;

    a
    {
      color: $primary-color;
    }

    section
    {
      width:90%;
      margin: auto;
    }
  

     @include mq("tablet-wide","min")
    {
       padding: 30px 70px 50px;
       padding-right: 18%;
      font-size: inherit
    }
  }
