@import "../../assets/scss/index.scss";
.page_title {
  min-height: 100px;
  width: 100%;
  padding: 35px;
  background: $primary-color;
  @include flex-center;
  color: #fff;
  h1 {
    font-weight: map-get($font-weights, "black");
    font-size: 30px;
  }

   @include mq("tablet","min")
    {
      padding: 60px 20px;
    }
}
.info_article {
  font-size: 20px;
  &,p{
    font-weight: map-get($font-weights, "semibold");
  }
  color: #303030;
  line-height: 2;
  .inner {
    padding: 70px 10px;

    @include mq("tablet-wide","min")
    {
      padding: 110px;
    }

    ul li
    {
       position: relative;
       margin-left: 20px;
       padding-left: 40px;
       margin-bottom: 25px;


       &:before
       {
         content: " ";
         position: absolute;
         width: 15px;
         height: 15px;
         left: 0;
         top: 50%;
         transform: translateY(-50%);
         border-radius: 50%;
         background-color:$primary-color;
       }
    }
  }
  section{
      min-height:100px;
  }
  p.effect{
      color:$primary-color;
      margin-top: 10px;
      margin-bottom: 50px;

      @include mq("fluid-out","min")
      {
         margin-bottom: 100px;

      }
  }
  ol 
  {
      margin-top:50px;
      
    div
    {
        padding:0 20px;

        @include mq("tablet","min")
        {
           padding: 0 50px;
        }
    }
    ::marker
    {
        color: $primary-color;
        content: counter(list-item) "      ";
    }
    li 
    {
      list-style: decimal;
      margin: 30px;
    }
  }
}
