//APP COLOR SCHEME
$primary-color: #3578E5;
$secondary-color: #000000;
$primary-text-color: #022B69;
$secondary-text-color: #707070;

//STATE SCHEME
$borders_and_disabled: #e8e8e8;
$error: #fb4e4e;
$warning: #ffba1d;
$success: #2ac769;
$inactive: #a0a4a8;
$btn-disabled: #f9f9fa;
$info: #13139c;

//OTHERS
$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "fluid-out":960px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);
//this font-weight relies on the poppins font loaded
$font-weights: (
    "extralight":200,
    "light":300,
    "regular":400,
    "medium":500,
    "semibold":600,
    "bold":700,
    "extrabold":800,
    "black":900
);
%btn{
    font-family: 'Mulish', Romans, sans-serif;
    padding:1.1rem 2rem;
    display: inline-block;
    border-radius: 10px;
    font-weight: map-get($font-weights, "semibold" );
    font-size: 12px;
    border: none;

}
$shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
$bottom-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.1);
$menu-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
